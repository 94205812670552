<script setup lang="ts">
useHead({
  titleTemplate: titleChunk => [titleChunk, 'Template Admin'].filter(Boolean).join(' - '),
  bodyAttrs: {
    class: 'overflow-hidden',
  },
  htmlAttrs: {
    class: 'overflow-hidden antialiased',
  },
})
</script>

<template>
  <UiTooltipProvider>
    <NuxtPage />
  </UiTooltipProvider>
  <UiToaster
    position="top-right"
    class="right-4 top-[calc(var(--header-height)+1rem)]"
  />
</template>
