export default defineNuxtRouteMiddleware(async () => {
  const config = useRuntimeConfig()
  const { user } = useUser()
  const { getUser } = useFetchUser()

  if (!user.value) {
    try {
      const response = await getUser()
      user.value = response.data
    }
    catch {
      return navigateTo(config.public.loginUrl, {
        external: true,
      })
    }
  }
})
