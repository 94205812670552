import type { Permission, UserData } from '~/types'

export default createGlobalState(() => {
  const user = ref<UserData | null>(null)

  const can = (permission: Permission) => computed(() => user.value?.permissions.includes(permission))

  return {
    user,
    can,
  }
})
