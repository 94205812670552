import { useFetchWithDefaults } from './useFetchWithDefaults'
import type { Response } from '~/types/api'
import type { UserData } from '~/types'

export const useFetchUser = () => {
  const { get } = useFetchWithDefaults()

  return {
    getUser: () => get<Response<UserData>>({ url: '/api/user' }),
  }
}
