import { default as _91contractId_93w3nhizJw3CMeta } from "/vercel/path0/apps/template-admin/pages/build/[templateVersionId]/[contractId].vue?macro=true";
import { default as _91templateVersionId_938VSPbqFbloMeta } from "/vercel/path0/apps/template-admin/pages/edit/[templateId]/[templateVersionId].vue?macro=true";
import { default as indexXapLqUsFwTMeta } from "/vercel/path0/apps/template-admin/pages/index.vue?macro=true";
export default [
  {
    name: "build-templateVersionId-contractId",
    path: "/build/:templateVersionId()/:contractId()",
    component: () => import("/vercel/path0/apps/template-admin/pages/build/[templateVersionId]/[contractId].vue")
  },
  {
    name: "edit-templateId-templateVersionId",
    path: "/edit/:templateId()/:templateVersionId()",
    meta: _91templateVersionId_938VSPbqFbloMeta || {},
    component: () => import("/vercel/path0/apps/template-admin/pages/edit/[templateId]/[templateVersionId].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexXapLqUsFwTMeta || {},
    component: () => import("/vercel/path0/apps/template-admin/pages/index.vue")
  }
]